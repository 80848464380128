._3sXmF {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: background 0.2s ease;
  min-height: 28px;
}

._3sXmF:hover {
  cursor: pointer;
  background: rgba(220, 220, 220, 0.4);
}

._ygkSb {
  overflow-y: auto;
  transition: background 0.2s ease;
}

/* Width */
._ygkSb::-webkit-scrollbar {
  width: 8px;
}
/* Track */
._ygkSb::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
._ygkSb::-webkit-scrollbar-thumb {
  background: #ccc;
}
/* Handle on hover */
._ygkSb::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
/* Handle on drag */
._ygkSb::-webkit-scrollbar-thumb:active {
  background: #666;
}

._ygkSb:hover {
  cursor: pointer;
  background: rgba(220, 220, 220, 0.4);
}

._1EEDX {
  width: 100%;
  padding: 5px;
  display: block;
  margin: 3px 0;
  scrollbar-width: thin;
}

/* Width */
._1EEDX::-webkit-scrollbar {
  width: 8px;
}
/* Track */
._1EEDX::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
._1EEDX::-webkit-scrollbar-thumb {
  background: #ccc;
}
/* Handle on hover */
._1EEDX::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
/* Handle on drag */
._1EEDX::-webkit-scrollbar-thumb:active {
  background: #666;
}

._3qwMT {
  color: #999;
}

._3F-ST {
  display: inline !important;
}

._38077 {
  cursor: auto !important;
}

._38077:hover {
  cursor: auto !important;
}

._2_49w {
  display: flex;
}

._PG2W0 {
  background-color: #fff;
  color: black;
  border: 0;
  outline: none;
  min-width: 28px;
}

._PG2W0:focus {
  border: 0;
  outline: none;
}
